/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// GA timers
				if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '0', '0-5 seconds', { 'nonInteraction': 1 }); }
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '1', '6-10 seconds', { 'nonInteraction': 1 }); }
				}, 6000);
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '2', '11-30 seconds', { 'nonInteraction': 1 }); }
				}, 11000);
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '3', '31-60 seconds', { 'nonInteraction': 1 }); }
				}, 31000);
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '4', '61-180 seconds', { 'nonInteraction': 1 }); }
				}, 61000);
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '5', '181-600 seconds', { 'nonInteraction': 1 }); }
				}, 181000);
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '6', '601-1800 seconds', { 'nonInteraction': 1 }); }
				}, 601000);
				setTimeout(function () {
					if (typeof ga === 'function') { ga('send', 'event', 'TimeOnPage', '7', '1801+ seconds', { 'nonInteraction': 1 }); }
				}, 1801000);

				// JavaScript to be fired on all pages
				var body = $('body'),
					menu = $('#menu');

				// Menu
				menu.mmenu({
					slidingSubmenus: false,
					navbar: {
						add: false
					}
				}, {
					classNames: {
						selected: 'active'
					}
				});

				var menuApi = menu.data('mmenu');
				$('#menu-toggle').on('click', function () {
					if (menu.hasClass('mm-opened')) {
						menu.removeClass('mm-opened');
						menuApi.close();
					}
					else {
						menuApi.open();
					}
				});

				// Scroll spy with scroll to top button visibility
				body.scrollspy({ target: '.main-hero-menu' });
				$(document).on('scroll resize', function (event) {
					body.toggleClass('topped', $(this).scrollTop() < $(window).height() / 2);
				});

				// Smooth scroll
				body.on('click', '.scrollto', function(event) {
					if ((location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') || location.hostname === this.hostname)) {
						var target = $(this.hash);
						target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
						var offset = target.data('offset') || 0;

						if (target.length) {
							$('html, body').animate({ scrollTop: target.offset().top - offset }, 500);
							event.preventDefault();
						}
					}
				});

				// Sticky
				//$('.main-hero-menu').sticky({ topSpacing: 47 });
				$('.header-strip').sticky();
				$('.header-menu').sticky();

				// Main Carousel
				$('.main-hero-carousel').each(function () {
					var hero = $(this);

					hero.owlCarousel({
						autoplay: true,
						loop: true,
						dots: true,
						animateOut: 'fadeOut',
						items: 1
					});
					hero.on('changed.owl.carousel', function (event) {
						hero.trigger('stop.owl.autoplay').trigger('play.owl.autoplay');
					});
				});

				// Farms info
				$('.farms-map-tile').on('click', function (event) {
					event.preventDefault();

					var target = $($(this).attr('href'));

					if ($(target).length) {
						$('.farms-map-tile').removeClass('active');
						$(this).addClass('active');

						$('.farms-map-info').removeClass('active');
						target.addClass('active');
					}
				});

				// Lamb cuts
				$('.cuts-wrap').each(function () {
					var wrap = $(this),
						list = $('.cuts-list', wrap),
						desc = $('.cuts-desc', wrap),
						cuts = $('.cut', wrap);

					list.on('click', 'a', function (event) {
						var link = $(this),
							id = link.attr('href').substr(1);

						event.preventDefault();

						if ($(this).hasClass('active')) {
							return;
						}

						$('.active', wrap).removeClass('active');
						link.addClass('active');
						$('.cuts-desc-' + id, desc).addClass('active');
					});

					cuts.on('click', function (event) {
						var cut = $(this),
							id = cut.data('id');

						$('.active', wrap).removeClass('active');
						cuts.filter('.active').attr('class', 'cut');
						cut.attr('class', 'cut active');
						$('.cuts-desc-' + id, desc).addClass('active');
					});

					$('.panel-body a[href^="#"]', desc).on('click', function (event) {
						event.preventDefault();
					}).tooltip({
						html: true,
						title: function() {
							var target = $(this).attr('href').substring(1),
								panel = $(this).parents('.panel:first');

							var image = $('.cut-img-' + target, panel);
							return image.length ? image.clone() : '';
						}
					});


				});

				// Recipe carousel
				function initRecipeCarousel(target) {
					var carousel = target ? $('.recipe-carousel', target) : $('.recipe-carousel');

					carousel.owlCarousel({
						autoplay: false,
						loop: true,
						autoWidth: false,
						navRewind: false,
						dots: false,
						center: false,
						nav: true,
						navText: ['', ''],
						responsive: {
							0: {
								items: 1
							},
							768: {
								items: 2
							},
							992: {
								items: 3
							}
						}
					});
				}
				initRecipeCarousel();

				function reinitAddThis() {
					if (window.addthis) {
						window.addthis = null;
						window._adr = null;
						window._atc = null;
						window._atd = null;
						window._ate = null;
						window._atr = null;
						window._atw = null;
					}
					$.getScript(window.wpAddThis.url);
				}

				// Ajax Modal
				$('#modal').on('show.bs.modal', function (event) {
					var link = $(event.relatedTarget),
						modal = $(this);

					modal.find('.modal-body').empty().load(link.attr('href'), function () {
						initRecipeCarousel(modal);
						if (typeof ga !== 'undefined') {
							ga('send', 'event', 'Modal', 'Open', link.attr('href'));
						}
						reinitAddThis();
					});
				});

				// Recipe print
				$(document).on('click', '.recipe-info-print', function (event) {
					event.preventDefault();
					window.print();
				});

				// Close modal if view other recipes were clicked from inside of it
				$(document).on('click', '.recipe-info-others-link', function (event) {
					var modal = $(this).parents('#modal');

					if (modal.length) {
						event.preventDefault();
						modal.modal('hide');
					}
				});

				// Toggle open search
				$('.header-search-toggle').on('click', function (event) {
					event.preventDefault();
					$(this).parent().toggleClass('open');
				});

				// Activate video
				var videoTiles = $('.section-tile-video');
				if (videoTiles.length) {
					var tag = document.createElement('script');
					tag.src = "//www.youtube.com/player_api";
					var firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

					videoTiles.on('click', function (event) {
						$(this).addClass('active');
					});
				}

				// Active team member
				$('.about-staff-item.togglable').on('click', function (event) {
					if ($(this).hasClass('active')) {
						return;
					}

					$(this).addClass('active')
						.siblings().removeClass('active');
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function onYouTubePlayerAPIReady() {
	jQuery('.section-tile-video').each(function (i, item) {
		var tile = jQuery(this);
		tile.find('iframe').attr('id', 'section-tile-video-' + i);

		var player = new YT.Player('section-tile-video-' + i, {
			events: {
				onReady: function () {
					tile.on('click', function (event) {
						player.playVideo();
					});
					if (tile.hasClass('active')) {
						player.playVideo();
					}
				}
			}
		});
	});
}
